export const colors = [
  '#ff4b4b',
  '#ffb74b',
  '#fff34b',
  '#c0ff4b',
  '#4bff81',
  '#4bfff0',
  '#4b9fff',
  '#574bff',
  '#c64bff',
  '#ff4be1',
  '#ff4b87',
];
